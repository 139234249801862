var recaptchaResponse

$(document).ready(function () {
  const submitButton = $('[type="submit"]')
  const verifyRecaptchaServiceURL = $('[data-verify-recaptcha]').attr('data-verify-recaptcha')

  if (verifyRecaptchaServiceURL) {
    submitButton.prop('disabled', true)
  }

  // Add class "touched" to inputs, to apply error classes if necessary
  submitButton.on('click', function (e) {
    $('.contact-form input , .contact-form textarea').addClass('touched')
  })

  const submitForm = async function (e) {
    const senderIp = ''

    submitButton.text('Sending')
    submitButton.append('<span class="loader"></span>')

    const formElements = await $('.contact-form__form').serializeArray()
    formElements.push({ name: 'senderIp', value: senderIp })

    if (typeof mailReceivers !== 'undefined') {
      formElements.push({ name: 'Receivers', value: mailReceivers })
    }

    const serviceUrl = submitButton.data().service
    const form = $('.contact-form__form')

    $.ajax({
      method: 'POST',
      url: serviceUrl,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(formElements),
      success: function (data) {
        if (data.success) {
          form.hide()
          $('.contact-form__header').hide()
          $('.contact-form__tankyou').fadeIn('fast')
        } else {
          submitButton.text('Send')
          submitButton.remove('.loader')
        }
      }
    })
  }

  // show thank you screen
  $('.contact-form__form').submit(async function (e) {
    e.preventDefault()

    if (verifyRecaptchaServiceURL) {
      fetch(verifyRecaptchaServiceURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({ recaptchaResponse })
      }).then((response) => {
        if (response.status !== 200) {

          return
        }
        submitForm(e)
      }).catch(() => {
      })
    } else {
      submitForm(e)
    }
  })

  // show contact form again
  $('.contact-form__tankyou button').on('click', function () {
    grecaptcha.reset()
    $('.contact-form__form').fadeIn('fast')
    $('.contact-form__header').fadeIn('fast')
    $('.contact-form__tankyou').hide()

    $('[type="submit"]').text('Send')
    $('[type="submit"]').remove('.loader')

    // $('[name=contactForm]').find('[type="submit"]').prop('disabled', true)

    $('.contact-form__form').each(function () {
      $(this).find(':input').each(function () {
        $(this).removeClass('touched')
      })
      this.reset()
    })
  })
})

window.recaptchaSuccess = function () {
  recaptchaResponse = grecaptcha.getResponse()
  if (recaptchaResponse.length > 0) {
    $('[type="submit"]').prop('disabled', false)
  }
}

window.recaptchaExpired = function () {
  $('[type="submit"]').prop('disabled', true)
}

window.recaptchaError = function () {
  $('[type="submit"]').prop('disabled', true)
}
